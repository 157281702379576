<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >

    <v-row>

      <!-- ACH TRANSFER SHEET - UPLOAD AND CONFIG -->
      <v-col cols="12" lg="6">
        <v-card outlined class="pa-6">
          <v-card-title>
            Upload your transfer sheet (.xlsx only)
          </v-card-title>

          <v-card-text>

            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-radio-group row v-model="form.upload.model.acquirer">
                      <template v-slot:label>
                        <div>收單行<br></div>
                      </template>
                      <v-radio label="台新銀行" value="taishin"></v-radio>
                      <v-radio label="富邦銀行" value="fubon"></v-radio>             
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-space-between">
                    <v-file-input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      label="Upload XLSX file" v-model="form.upload.model.file" @change="previewExcel">
                    </v-file-input>
                    <v-btn rounded color="default" @click="downloadSampleAchSheet">
                      <v-icon left>mdi-file-download-outline</v-icon>
                      Download Sample
                    </v-btn>
                  </v-col>

                  <v-col cols="6">
                    <v-menu ref="schedule-date-picker" v-model="form.upload.menu"
                      offset-y min-width="auto" :close-on-content-click="false"
                      :return-value.sync="form.upload.model.schedule" transition="scale-transition" >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field label="Scheduled date to transfer" offset-y min-width="auto"
                          v-model="form.upload.model.schedule"
                          prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on">
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="form.upload.model.schedule" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="form.upload.menu = false">Cancel</v-btn>
                        <v-btn text color="primary"
                          @click="$refs['schedule-date-picker'].save(form.upload.model.schedule)">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                </v-row>
              </v-container>
            </v-form>

          </v-card-text>

          <v-card-actions class="d-flex justify-end">
            <v-btn depressed large color="error" @click="upload">
              <v-icon left>mdi-file-upload-outline</v-icon>
              Upload
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- PREVIEW OF TRANSFER SHEET -->
      <v-col cols="12">
        <v-expand-transition>
          <v-card class="pa-6" v-show="expansion.preview.show">
            <v-card-title>Preview</v-card-title>
            <v-card-text>
              <SSRTable ref="ach-preview" :data="records" :schema="previewSchema">
                <!-- <div slot="invoice" slot-scope="{item}">
                  <span> {{item.invoice_type}} </span><br/>
                  <span> {{item.invoice_email}} </span><br/>
                  <span> {{item.invoice_title}} </span><br/>
                  <span> {{item.invoice_tax_id}} </span>
                </div> -->
              </SSRTable>
            </v-card-text>
          </v-card>
        </v-expand-transition>
      </v-col>

      <!-- ACTIONS -->
      <v-col cols="12">
        
      </v-col>

    </v-row>

    <v-dialog v-model="dialog.upload.show" @click:outside="false">
      <v-card>
        <v-card-title>
          Upload Result 
        </v-card-title>
        <v-card-text>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Batch reference ID</v-list-item-title>
              <v-list-item-subtitle>{{ dialog.upload.refId }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Batch description (transfer note)</v-list-item-title>
              <v-list-item-subtitle class="red--text">{{ dialog.upload.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Total amount</v-list-item-title>
              <v-list-item-subtitle>${{ Number(dialog.upload.uploadResult.amounts).toLocaleString() }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ dialog.upload.uploadResult.correct }} records are successful.
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ dialog.upload.uploadResult.error }} records have errors.
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <span class="text-h5">Error Records</span>
          <SSRTable ref="upload-error-records"
            :data="dialog.upload.errorRecords" :schema="dialog.upload.errorRecordSchema">
          </SSRTable>
        </v-card-text>
        <v-card-actions>
          <!-- <v-btn color="secondary" @click="cancelSubmit">Cancel</v-btn> -->
          <v-btn color="error" @click="submit">Submit to Bank</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
  import SSRTable from '@/components/SSRTable';

  import SimpleVueValidation from 'simple-vue-validator';
  import readXlsxFile from 'read-excel-file';
  import axios from 'axios';
  import moment from 'moment';

  const Validator = SimpleVueValidation.Validator;
  const MAP_UPLOAD_RECORD_KEY = {
    'merchant': 'merchantId',
    'recipient_bank': 'bankId',
    'recipient_id': 'accountId',
    'recipient_no': 'accountNo',
    'amount': 'amount',
    'invoice_type': 'invoiceType',
    'invoice_title': 'invoiceTitle',
    'invoice_tax_id': 'invoiceTaxId',
    'invoice_email': 'invoiceEmail',
    'invoice_amount': 'invoiceAmount',
    'note': 'note'
  };

  export default {
    components: {
      SSRTable
    },

    mounted() {

    },

    methods: {

      downloadSampleAchSheet() {
        window.open(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/ach/sample`);
      },

      previewExcel(file) {//FIXME: This may not handle large dataset.
        this.records = [];

        readXlsxFile(file)
        .then(rows => {
          // Check schema.
          let schema = rows.slice(0, 1)[0];
          if (
            schema.indexOf('merchant') < 0
            || schema.indexOf('recipient_bank') < 0
            || schema.indexOf('recipient_id') < 0
            || schema.indexOf('recipient_no') < 0
            || schema.indexOf('amount') < 0
          ) {
            console.warn(`[Transfer] Uploaded schema`, schema);
            throw new Error(`Invalid upload schema. Something is missing.`);
          }

          // Fill the table rows~
          rows.shift();
          rows.forEach(cells => {
            let record = { };
            for (let i=0; i<cells.length; i++) {
              let key = MAP_UPLOAD_RECORD_KEY[ schema[i] ];
              if (key) // 有定義的才上傳
                record[key] = cells[i];
            }
            this.records.push(record);
          });
          this.$refs['ach-preview'].loadData();

          this.expansion.preview.show = true;
        })
        .catch(err => {
          console.error('[Transfer] readXlsxFile failed', err);
          this.$notify({
            text: `Cannot read this file: ${err.message}`,
            type: 'error'
          });
        });

      },
      
      upload() {
        if (!this.form.upload.model.file) {
          alert(`You must upload an excel file.`);
          return;
        }
        console.log(`[Transfer] form.upload.model`, this.form.upload.model, this.records);
        axios.post(
          `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/ach/records`,
          {
            schedule: moment(this.form.upload.model.schedule).format('YYYYMMDD'),
            records: this.records,
            version: this.form.upload.model.acquirer === 'fubon' ? 'v10' : null,
            acquirer: this.form.upload.model.acquirer
          }
        )
        .then(response => {
          console.warn(`[Transfer] Upload succeeded`, response.data);

          // Show a dialog to inform about upload result.
          this.dialog.upload.refId = response.data['refId'];
          this.dialog.upload.description = response.data['description'];
          this.dialog.upload.uploadResult = response.data['result'];
          this.dialog.upload.errorRecords = response.data['errorRecords'];
          this.dialog.upload.show = true;

          this.$notify({
            text: `ACH transfer request upload succeeded.`,
            type: 'success'
          });
        })
        .catch(err => {
          console.error(`[Transfer] Upload failed`, err);
          this.$notify({
            text: `ACH transfer request upload failed: ${err.message}`,
            type: 'error'
          });
        });
        
      },
      
      submit() {
        // if (!confirm('You are about to submit the ACH requests to bank. Sure to continue?'))
        //   return;
        if (!confirm('You are about to download ACH transfer file. Sure to continue?'))
          return;

        let refId = this.dialog.upload.refId;
        if (!refId) {
          console.error(`[Transfer] No ref ID?!!`);
          return;
        }

        window.open(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/ach/preview/${refId}`)
        // axios.post(
        //   `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/ach/submit/${refId}`,
        //   { 
        //     schedule: moment(this.form.upload.model.schedule, 'YYYY-MM-DD').format('YYYYMMDD'),
        //     batchNo: this.form.upload.model.batchNo
        //   }
        // )
        // .then(response => {
        //   console.warn(`[Transfer] Submission succeeded`, response.data);
        //   this.$notify({
        //     text: `ACH transfer request submission succeeded.`,
        //     type: 'success'
        //   });

        //   //TODO: Redirect to batch result page?
        // })
        // .catch(err => {
        //   console.error(`[Transfer] Submission failed`, err);
        //   this.$notify({
        //     text: `ACH transfer request submission failed: ${err.message}`,
        //     type: 'error'
        //   });
        // })
        // .finally(() => {
        //   this.dialog.upload.show = false;
        // });
      }

    },

    data() {
      return {

        records: [],
        previewSchema: [
          { value: 'merchantId', text: 'Merchant' },
          { value: 'bankId', text: 'Rcpnt Bank' },
          { value: 'accountId', text: 'Rcpnt' },
          { value: 'accountNo', text: 'Rcpnt Account' },
          { value: 'invoiceType', text: 'Invoice Type' },
          { value: 'invoiceTitle', text: 'Co Title' },
          { value: 'invoiceTaxId', text: 'Tax ID' },
          { value: 'invoiceEmail', text: 'Email' },
          { value: 'invoiceAmount', text: 'Invoice $', align: 'right' },
          { value: 'amount', text: '$', align: 'right' },
          { value: 'note', text: 'Note' },
        ],
        
        form: {
          upload: {
            menu: false,
            model: {
              acquirer: 'taishin',
              file: undefined,
              description: '',
              schedule: moment().add(1, 'days').format('YYYY-MM-DD'),
              batchNo: '01'
            }
          }
        },
        expansion: {
          preview: {
            show: false
          }
        },
        dialog: {
          upload: {
            show: false,
            refId: undefined,
            description: '',
            uploadResult: { },
            errorRecords: { },
            errorRecordSchema: [
              { value: 'merchantId', text: 'Merchant' },
              { value: 'bankId', text: 'Rcpnt Bank' },
              { value: 'accountId', text: 'Rcpnt' },
              { value: 'accountNo', text: 'Rcpnt Account' },
              { value: 'amount', text: '$', align: 'right' },
              { value: 'note', text: 'Note' },
              { value: 'errorMsg', text: 'Error' }
            ]
          }
        }
        
      };
    },

    // validators: {
    //   'form.upload.model.description': v => {
    //     return Validator.value(v).required().minLength(1).maxLength(8);
    //   },
    //   'form.upload.model.batchNo': v => {
    //     return Validator.value(v).required().length(2).regex(new RegExp('[0-9][0-9]', 'g'));
    //   }
    // }
  }
</script>
